<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('agentlist_xq')" id="fzcolor">基本信息</span>
      <span @click="toTypeUrl('add_agentlist_js')">结算信息</span>
      <span @click="toTypeUrl('add_agentlist_cp')">产品信息</span>
    </div>
    <div class="header" v-else>
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('add_agentlist_js')">结算信息</span>
      <span @click="toUrl('add_agentlist_cp')">产品信息</span>
    </div>
    <div class="desc">
        <h5 class="jsxx">基本信息</h5>
    </div>
    <div class="input_con">
      <ul class="inputBox">
        <li>
          <span><i>*</i>代理商名称</span>
          <el-input
            class="inputs"
            v-model="formData.agentName"
            maxlength="128"
            placeholder="输入代理商名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人</span>
          <el-input
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="2"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>手机号码</span>
          <el-input
            class="inputs"
            v-model="formData.contactTel"
            maxlength="11"
            placeholder="输入手机号码"
            tabindex="3"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>邮箱</span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入邮箱"
            tabindex="4"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系地址</span>
          <el-input
            class="inputs"
            v-model="formData.contactAddress"
            maxlength="128"
            placeholder="输入联系地址"
            tabindex="5"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人姓名</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="6"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人身份证号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="7"
          ></el-input>
        </li>
        <li>
          <span>备注</span>
          <el-input
            class="inputs"
            v-model="formData.remark"
            maxlength="128"
            placeholder="输入备注"
            tabindex="8"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph">
      <div class="uploader1">
        <h4><i>*</i>营业执照</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>身份证正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>开户许可证</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="accountOpeningLicenseSuccess"
        >
          <img
            v-if="formData.accountOpeningLicense"
            :src="formData.accountOpeningLicense"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.accountOpeningLicense" class="avatar-uploader-span" @click.stop="formData.accountOpeningLicense = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="upload_wj clear">
      <div class="upload_wj_left">
        <h5>代理商协议</h5>
        <!-- :before-remove="beforeRemove" -->
        <el-upload
          class="upload-demo"
          :before-upload="beforeUpload"
          :action="uploadUrl"
          :on-preview="handlePreview"
          :on-success="mchProtocolSuccess"
          :on-remove="mchProtocolRemove"
          :before-remove="beforeRemove"
          multiple
          :file-list="mchProtocolList"
        >
          <el-button size="small" type="primary" class="djsc"
            >点击上传</el-button
          >
        </el-upload>
      </div>
    </div>
    <div class="bottom" v-if="pageType == 'edit'">
      <el-button class="back" type="primary" @click="getBack">返回</el-button>
      <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
    </div>
    <div class="bottom" v-else>
      <el-button @click="toUrl('add_agentlist_js')" class="next" type="primary"
        >下一步</el-button
      >
    </div>
    <div class="cover_view" v-if="showImg !== ''" @click="showImg = ''">
      <a :href="showImg" target="_blank">
        <img :src="showImg" alt="加载失败" />
      </a>
    </div>
  </div>
</template>
<script>
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import { getAgentsDetail, setAgentsDetail } from "@/api/user/agentsManager.js";
import * as imageConversion from "image-conversion";
export default {
  data() {
    return {
      isImge: true, //判断代理商协议是否为图片
      formData: {
        agentName: "", //	true	代理商名称
        contactName: "", // true	联系人
        contactTel: "", //true	联系电话
        contactEmail: "", //true	邮箱
        contactAddress: "", //true	联系地址
        legalPersonName: "", //true	法人姓名
        legalPersonNo: "", //true	法人身份证号码
        remark: "", //false	备注
        licencePicture: "", //true	商户证件照片URL
        accountOpeningLicense: "", //false	开户许可证图片URL
        agentProtocol: "", //true	代理商协议
        idcardNormalPicture: "", //false	法人身份证正面照片
        idcardReversePicture: "", //false	法人身份证反面照片
        agentProtocol: "", //代理商协议
      },
      uploadUrl: URL_API.upload,
      mchProtocolList: [],
      showImg: "",
      pageType: "", //页面状态
    };
  },
  computed: {
    ...mapState({
      agentsDetail: "user_agentsDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.agentsDetail) {
        if (this.agentsDetail.agentDetail) {
          this.formData = this.agentsDetail.agentDetail;
          this.mchProtocolList = this.formData.agentProtocol
            ? JSON.parse(this.formData.agentProtocol)
            : [];
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setAgents: "user_setAgents",
    }),
    //获取详情
    getDetail() {
      getAgentsDetail(this.$route.query.id).then((res) => {
        if (res) {
          console.log("代理商数据", res);

          this.formData = res.resultData.agentDetail;
          //   如果有协议数据 就展示出来

          this.mchProtocolList = res.resultData.agentDetail.agentProtocol
            ? JSON.parse(res.resultData.agentDetail.agentProtocol)
            : [];
          // if (this.formData.agentProtocol) {
          //   let agentName = this.getCaption(
          //     JSON.parse(JSON.stringify(this.formData.agentProtocol))
          //   );
          //   let agentUrl = JSON.parse(
          //     JSON.stringify(this.formData.agentProtocol)
          //   );

          //   this.mchProtocolList.push({
          //     name: agentName,
          //     url: agentUrl,
          //   });
          // }
        }
      });
    },
    getCaption(str) {
      var index = str.lastIndexOf("/");
      str = str.substring(index + 1, str.length);
      return str;
    },

    // 商户证件上传
    licencePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 身份证正面照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 身份证反面照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 代理商协议
    mchProtocolSuccess(res) {
      let testmsg = res.resultData.fileName.substring(res.resultData.fileName.lastIndexOf(".") + 1);
      if (testmsg != "jpg" && testmsg != "JPG" && testmsg != "png" && testmsg != "PNG" && testmsg != "bpm" && testmsg != "BPM") {
        this.isImge = false;
        return false;
      }
      if (this.isImge && res.resultStatus) {
        let data = {
          name: res.resultData.fileName,
          url: res.resultData.fileLink,
        };
        this.mchProtocolList.push(data);
      } else {
        return false;
      }
    },
    //  移除商户协议前
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "confirmButton", //确定按钮的自定义类名
        cancelButtonText: "取消",
        cancelButtonClass: "cancelButton", //取消按钮的自定义类名
        type: "warning",
        closeOnClickModal: true, //是否可通过点击遮罩关闭
        closeOnPressEscape: false, //是否可通过按下 ESC 键关闭
      });
    },
    // 确定移除商户协议
    mchProtocolRemove(res, files) {
      this.mchProtocolList = [];
      files.forEach((v) => {
        let data = {
          name: v.name,
          url: v.url,
        };
        this.mchProtocolList.push(data);
      });
      this.formData.agentProtocol = "";
    },
    // 点击展示图片
    handlePreview(e) {
      this.showImg = e.url;
    },
    // 表单规则
    roles() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.agentName) {
        this.$message.error("代理商名称必填");
        return false;
      }
      if (!data.contactName) {
        this.$message.error("联系人必填");
        return false;
      }
      if (!data.contactTel) {
        this.$message.error("手机号码必填");
        return false;
      }
      if (
        !this.$route.query.id &&
        !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.contactTel)
      ) {
        this.$message.error("请正确填写手机号码");
        return;
      }
      if (!data.contactEmail) {
        this.$message.error("邮箱必填");
        return false;
      }
      if (!data.contactAddress) {
        this.$message.error("联系地址必填");
        return false;
      }
      if (!data.legalPersonName) {
        this.$message.error("法人姓名必填");
        return false;
      }
      if (!data.legalPersonNo) {
        this.$message.error("法人身份证号码必填");
        return false;
      } else {
        // 123156456
        if (data.legalPersonNo.indexOf("*") != -1) {
          console.log("法人身份证号有星号,判定为未做修改");
        } else {
          if (
            !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
              data.legalPersonNo
            )
          ) {
            this.$message.error("请输入合法的身份证号码");
            return false;
          }
        }
      }
      if (!data.licencePicture) {
        this.$message.error("营业执照必传");
        return false;
      }
      if (!data.idcardNormalPicture) {
        this.$message.error("法人身份证正面照片必传");
        return false;
      }
      if (!data.idcardReversePicture) {
        this.$message.error("法人身份证反面照片必传");
        return false;
      }
      if (!data.accountOpeningLicense) {
        this.$message.error("开户许可证必传");
        return false;
      }
      if(JSON.stringify(this.mchProtocolList).length > 1536){
        this.$message.error("超出上传上限,请先移除已上传文件");
        return false;
      }
      if (this.mchProtocolList.length > 0) {
        data.agentProtocol = JSON.stringify(this.mchProtocolList);
      }
      // console.log("修改后的代理商数据1",data)
      sessionStorage.setItem("agentDetail", JSON.stringify(data));

      return true;
    },
    // 页面跳转
    toUrl(name) {
      if (!this.roles()) {
        return;
      }
      this.$router.replace({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.replace({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
    // 修改保存
    onSubmit() {
      if (!this.roles()) {
        return;
      }

      let data = JSON.parse(sessionStorage.getItem("agentDetail"));
      console.log("修改后的代理商数据22", data);
      console.log(this.mchProtocolList,"0000000000000000000")
      // return;
      setAgentsDetail(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          let id = this.$route.query.id;
          this.$router.replace({
            name: "agentlist_xq",
            query: {
              id,
            },
          });
          // this.$router.go(-1);
        }
      });
    },
    // 点击返回
    getBack() {
      let id = this.$route.query.id;
      this.$router.replace({
        name: "agentlist_xq",
        query: {
          id,
        },
      });
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return false;
      }

      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (this.mchProtocolList.length > 0) {
        data.agentProtocol = JSON.stringify(this.mchProtocolList);
      }
      let agentsDetail = {
        agentDetail: data,
        settlements: this.agentsDetail ? this.agentsDetail.settlements : null,
        agentConfig: this.agentsDetail ? this.agentsDetail.agentConfig : null,
      };
      this.setAgents(agentsDetail);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
}

.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}

.djsc {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
}

.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}
.upload_wj_left i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
}

.uploader1 h4 i {
  color: #48b8b6;
  padding-right: 3px;
}

.uploader2 {
  margin-left: 30px;
  margin-right: 19px;
  float: left;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}

.ul_right {
  float: right;
  height: 100%;
  text-align: right;
  margin-right: 28px;
}

.ul_left {
  float: left;
  width: 492px;
  height: 100%;
  text-align: right;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
</style>
